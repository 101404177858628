import  { useCallback } from "react";

import "./data-room-files.scss";
import { FILE_EXTENSION_URL } from "constant";
import { formatFileSize } from "../../utils";
import { Json } from "../../types";
import { Button } from "@storybook";
import noData from "../../assets/images/no_data_light.svg";
import { Image } from "@storybook/image";
import { Tooltip as ReactTooltip } from "react-tooltip";

type IDataRoomFiles = {
  dataRoomFiles: Json[];
  loading?: boolean;
};
export const DataRoomFiles = ({ dataRoomFiles, loading = false }: IDataRoomFiles) => {

  const handleOpenDataRoom = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  return (
    <div className="data-room-files-container">
      <h2 className="data-room-heading">
        Data Room
        <p className="data-room-desc">
          Contains all the important documents for the SBA7.
        </p>
      </h2>

      {loading && (
        <></>
        // <SkeletonSearch className="data-room-files" listsToRender={2} />
      )}
      {!loading && !!dataRoomFiles?.length && (
        <div className="data-room-files">
          {dataRoomFiles?.map((file: Json) => (
            <div className="data-room-item" key={file?.id}>
              <div className="data-room-img">
                <img
                  src={FILE_EXTENSION_URL[file?.fileName?.split(".")?.[1]]}
                  alt={file?.docName}
                  onClick={() => handleOpenDataRoom(file?.fileUrl)}
                />
              </div>
              <div className="data-room-details">
                <div
                  className="name"
                  data-tooltip-id={file?.id}
                  onClick={() => handleOpenDataRoom(file?.fileUrl)}
                >
                  {file?.docName}
                </div>
                <ReactTooltip
                  className="theme-tooltip"
                  id={file?.id}
                  place="top"
                  content={file?.docName}
                  style={{ zIndex: 101 }}
                />
                <span>{formatFileSize(file?.fileSize || 0)}</span>
              </div>
              <Button
                // type="btn-light-blue"
                label="View"
                handleClick={() => handleOpenDataRoom(file?.fileUrl)}
                className={"btn-light-blue"}
              />
            </div>
          ))}
        </div>
      )}
      {!dataRoomFiles?.length && !loading && (
        <div className="no-data-found-mp">
          <Image url={noData} />
          No data available
        </div>
      )}
    </div>
  );
};
