import React, { useState } from "react";

import { ColumnBarGraph, LineChart } from "@storybook";

export const SbaDutch = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="revenue-chart-wrapper">
      <div className="revenue-chart__head">
        <div>
          <div className="revenue-chart__label">Auction</div>
          <h3>SBA7</h3>
        </div>
        <div className="revenue-chart__tag">Dutch</div>
      </div>
      <div>
        <div className="tabs">
          <div
            className={`tab revenue-chart__label ${
              activeTab === 1 ? "active" : ""
            }`}
            onClick={() => handleTabClick(1)}
          >
            Sales & Revenue{" "}
          </div>
          <div
            className={`tab revenue-chart__label ${
              activeTab === 2 ? "active" : ""
            }`}
            onClick={() => handleTabClick(2)}
          >
            Loans
          </div>
        </div>

        <div className="tab-content">
          {activeTab === 1 && <LineChart />}
          {activeTab === 2 && <ColumnBarGraph />}
        </div>
      </div>
    </div>
  );
};
