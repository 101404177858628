import { API_URL } from "constant";
import { useNetwork, useNotification } from "hooks";
import { useState } from "react";
import { Json } from "types";
import { getJsonToParams } from "utils";

export const useAccountApis = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState(false);
  const { get: fetchAccounts } = useNetwork();
  const { get: fetchCoOwnerList } = useNetwork();
  const { patch: updateAccountStatus } = useNetwork();
  const { errorNotification } = useNotification();

  const lockUnlockAccounts = async (
    id: string,
    payload: Json
  ): Promise<void> => {
    setLoading(true);
    let response: any;
    try {
      response = await updateAccountStatus(
        `${API_URL.UPDATE_ACCOUNT_STATUS}/${id}`,
        payload
      );
    } catch (e: any) {
      setError(true);
      errorNotification(e?.message || "Something went wrong!");
    } finally {
      setLoading(false);
      if (response?.response?.status === 200) {
        setData(response?.apiData?.data);
        return response?.apiData?.data;
      } else {
        errorNotification(response?.apiData?.message ?? "Something went wrong");
        setError(true);
      }
    }
  };

  const getAuctionList = async (query: Json = {}): Promise<void> => {
    setLoading(true);
    let response;
    try {
      response = await fetchAccounts(
        API_URL.ACCOUNTLIST + getJsonToParams(query)
      );
    } catch (e: any) {
      setError(true);
      errorNotification(e?.message || "Something went wrong!");
    } finally {
      setLoading(false);
      if (response?.response?.status === 200) {
        setData(response?.apiData?.data);
      } else {
        errorNotification(
          response?.apiData?.message ?? "You don't have permissions"
        );
        setError(true);
      }
    }
  };

  const getCoOwnerList = async (query: string): Promise<void> => {
    setLoading(true);
    let response;
    try {
      response = await fetchCoOwnerList(API_URL.COOWNER_LIST + query);
    } catch (e: any) {
      setError(true);
      errorNotification(e?.message || "Something went wrong!");
    } finally {
      setLoading(false);
      if (response?.response?.status === 200) {
        setData(response?.apiData?.data);
        return response?.apiData?.data;
      } else {
        setError(true);
      }
    }
  };


  const getSimpliciAccessToken = async (): Promise<void> => {
    let response;
    try {
      response = await fetchCoOwnerList(API_URL.SIMPLICI_TOKEN);
    } catch (e: any) {
      setError(true);
      errorNotification(e?.message || "Failed to genrate user token!");
    } finally {
      if (response?.response?.status === 200) {
        setData(response?.apiData?.data);
        return response?.apiData?.data;
      } else {
        setError(true);
      }
    }
  };

  return {
    getAuctionList,
    lockUnlockAccounts,
    getCoOwnerList,
    getSimpliciAccessToken,
    data,
    loading,
    error,
  };
};
