export const APP_ROUTES = {
  USER_LOGIN: "login",
  NO_ROUTE: "/",
  OAUTH: "oauth",
  USER_DETAILS: "user-details",
  REVENUE: "revenue",
  AUCTION: "auction",
  ROLES: "roles",
  ACTIVITY_LOGS: "activity-logs",
  ACCOUNTS: "accounts",
  MINTED_ASSETS: "private-stock",
  SBA7: "sba7",
  API_DESIGNER: "api-designer",
  ACCEPT_INVITE: "accept-invite",
  ACCESS_DENIED: "access-denied",
  USER_LOGIN_APPROVE: "mobile-verification"

};
