import  { useMemo } from "react";
import { ReactResponsiveTable } from "@storybook";
// @ts-ignore
import { Json } from "@types/common";
import "./sba7-loan-tables.scss"
export const Sba7LoanTables = ({ sba7Details }: Json) => {

  const renderBidSheetTable = useMemo(() => {
    if (!sba7Details?.bidSheetJSON?.length) return ""

    const column = Object.keys(sba7Details?.bidSheetJSON?.[0] || {})
    .filter((key) => key !== "marked")
    .map((key) => ({
      label: key,
      key: key,
      format: "string",
      width: "10%",
    }));

    return (
      <div className="sba7-bid-sheet">
        <h2 className="sba7-bid-sheet__heading">
          Bid Sheet
          <p className="sba7-bid-sheet__desc">
            The table below displays the processed bid sheet.</p>
        </h2>
        <ReactResponsiveTable
          className="bid-sheet-body"
          rows={sba7Details?.bidSheetJSON || []}
          column={column} />
      </div>
    );
  }, [sba7Details]);

  const renderSBA7Table = useMemo(() => {
    if (!sba7Details?.sba1502JSON?.length) return ""
    const column = Object.keys(sba7Details?.sba1502JSON?.[0] || {})
    .filter((key) => key !== "marked")
    .map((key) => ({
      label: key,
      key: key,
      format: "string",
      width: "10%",
    }));

    return (
      <div className="sba7-bid-sheet">
        <h2 className="sba7-bid-sheet__heading">
          SBA7 form 1502
          <p className="sba7-bid-sheet__desc">
            The table below displays the processed SBA form 1502</p>
        </h2>
        <ReactResponsiveTable
          className="bid-sheet-body"
          rows={sba7Details?.sba1502JSON || []}
          column={column} />
      </div>
    );
  }, [sba7Details]);

  return (
    <>
      {renderBidSheetTable}
      {sba7Details?.sba1502JSON?.length > 0  && (
        <>
          <hr className="middle-divider" />
          {renderSBA7Table}
        </>
      )}
    </>
  );
};
