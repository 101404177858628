import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Tooltip as ReactTooltip } from "react-tooltip";


import { useNetwork } from "hooks";
import { API_URL } from "constant";
import {
  FILE_EXTENSION_URL,
  selectedAssetState,
  assetListCall,
} from "../store";
import { formatDate, formatFileSize } from "utils";
import { TableEmptyMessage } from "@storybook/react-responsive-table/components";
import { Loader } from "@storybook";

import "./history.scss";

export const History = () => {
  const selectedAsset = useRecoilValue(selectedAssetState);
  const [assetApiCall, setAssetListCall] = useRecoilState(assetListCall);

  const { get: getHistory, data: HistoryData, isLoaded } = useNetwork();
  const { post: postHistoryView, data: viewData } = useNetwork();

  useEffect(() => {
    getHistory(`${API_URL.ASSET_HISTORY}/${selectedAsset?.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset?.id]);

  useEffect(() => {
    if (HistoryData?.data?.history?.length) {
      const historyItems = HistoryData?.data?.history?.filter(
        (item: any) => !item?.readStatus
      );
      const historyIds = historyItems.map((item: any) => item?.id);
      if (historyIds?.length) {
        postHistoryView(`${API_URL.ASSET_HISTORY_READ}`, {
          assetHistoryIds: historyIds,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HistoryData]);

  useEffect(() => {
    if (viewData?.data) {
      setAssetListCall(!assetApiCall);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData]);

  const handelView = useCallback((fileUrl: string | URL) => {
    window.open(fileUrl, "_blank");
  }, []);

  const HistoryRow = useCallback(
    (item: any, index: number) => {
      switch (item?.action) {
        case "APPROVED":
          return (
            <>
              <div className="history--log approved">
                <div className="history-row">
                  <div className="history-left">
                    <div className="history-icon">
                      <i className="ri-checkbox-circle-line"></i>
                    </div>
                  </div>

                  <div className="history-right">
                    {" "}
                    <span className="name">
                      {item?.super_admin?.firstName}{" "}
                    </span>
                    has <span className="action">approved</span> the{" "}
                    {HistoryData?.data?.history[index + 1]?.action ===
                      "RESUBMITTED" && (
                      <>
                        <span className="action">resubmission</span> of the{" "}
                      </>
                    )}
                    {selectedAsset?.issuerDetails?.firstName}{selectedAsset?.issuerDetails?.lastName}{selectedAsset?.name} ( {selectedAsset?.issuerDetails?.firstName}{selectedAsset?.issuerDetails?.lastName}{selectedAsset?.symbol}) SBA7
                    asset.
                    <div className="history-date">
                      {formatDate(item?.createdAt)}
                    </div>
                  </div>
                </div>
                <div className="history-row">
                  <div className="history-data">
                    {item?.filesMetadata?.newDocuments?.map((file: any) => (
                      <div className="support--doc">
                        {" "}
                        <div className="doc-img">
                          <div className="doc--img">
                            <img
                              src={
                                FILE_EXTENSION_URL[
                                  file?.docName?.split(".").pop().toLowerCase()
                                ]
                              }
                              alt=""
                            />
                          </div>
                          <div className="support--doc_name">
                            {file?.docName}{" "}
                          </div>
                          <div className="dot"></div>
                          {formatFileSize(file?.fileSize)}
                        </div>
                        <div className="support-action">
                          <button onClick={() => handelView(file?.fileUrl)}>
                            View
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          );
        case "RESUBMITTED":
          return (
            <>
              <div className="history--log">
                <div className="history-row">
                  <div className="history-left">
                    <div className="history-icon">
                      <i className="ri-user-shared-2-line"></i>
                    </div>
                  </div>

                  <div className="history-right">
                    {" "}
                    <span className="name">
                      {item?.admin_user?.firstName}
                    </span>{" "}
                    has <span className="action">resubmitted</span> the{" "}
                    {selectedAsset?.issuerDetails?.firstName}{selectedAsset?.issuerDetails?.lastName}{selectedAsset?.name}  ({selectedAsset?.issuerDetails?.firstName}{selectedAsset?.issuerDetails?.lastName}{selectedAsset?.symbol}) SBA7
                    asset.
                    <div className="history-date">
                      {" "}
                      {formatDate(item?.createdAt)}
                    </div>
                  </div>
                </div>
                <div className="history-row">
                  <div className="history-data">
                    {item?.filesMetadata?.newDocuments?.length > 0 && (
                      <div className="action new-doc"> New document</div>
                    )}
                    {item?.filesMetadata?.newDocuments?.map((file: any) => (
                      <div className="support--doc">
                        {" "}
                        <div className="doc-img">
                          <div className="doc--img">
                            <img
                              src={
                                FILE_EXTENSION_URL[
                                  file?.docName?.split(".").pop().toLowerCase()
                                ]
                              }
                              alt=""
                            />
                          </div>
                          <div className="support--doc_name">
                            {file?.docName}{" "}
                          </div>
                          <div className="dot"></div>
                          {formatFileSize(file?.fileSize)}
                        </div>
                        <div className="support-action">
                          <button onClick={() => handelView(file?.fileUrl)}>
                            View
                          </button>
                        </div>
                      </div>
                    ))}
                    {item?.filesMetadata?.removedDocuments?.length > 0 && (
                      <div className="action">Removed document</div>
                    )}
                    {item?.filesMetadata?.removedDocuments.map((file: any) => (
                      <div className="support--doc">
                        {" "}
                        <div className="doc-img">
                          <div className="doc--img">
                            <img
                              src={
                                FILE_EXTENSION_URL[
                                  file?.docName?.split(".").pop().toLowerCase()
                                ]
                              }
                              alt=""
                            />
                          </div>
                          <div className="support--doc_name">
                            {file?.docName}{" "}
                          </div>
                          <div className="dot"></div>
                          {formatFileSize(file?.fileSize)}
                        </div>
                        <div className="support-action">
                          <button onClick={() => handelView(file?.fileUrl)}>
                            View
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          );
        case "REJECTED":
          return (
            <>
              <div className="history--log history-comment">
                <div className="history-row">
                  <div className="history-left">
                    <div className="history-icon">
                      <i className="ri-indeterminate-circle-fill"></i>
                    </div>
                  </div>

                  <div className="history-right">
                    {" "}
                    <span className="name">
                      {item?.super_admin?.firstName}
                    </span>{" "}
                    has <span className="action">rejected</span>{" "}
                    {selectedAsset?.issuerDetails?.firstName}{selectedAsset?.issuerDetails?.lastName}{selectedAsset?.name} ({selectedAsset?.issuerDetails?.firstName}{selectedAsset?.issuerDetails?.lastName}.{selectedAsset?.symbol}) SBA7
                    asset.
                    <div className="history-date">
                      {" "}
                      {formatDate(item?.createdAt)}
                    </div>
                  </div>
                </div>
                <div className="history-row">
                  <div className="history-data">
                    <div className="doc--comment">
                      <div className="doc--img">
                        <i className="ri-chat-2-line"></i>
                      </div>
                      <div className="support--doc_name" data-tooltip-id={item?.rejection_reason}>
                        {item?.rejection_reason}
                      </div>
                      <ReactTooltip className="tooltip" id={item?.rejection_reason} place="right" content={item?.rejection_reason} />
                    </div>
                    {item?.filesMetadata?.newDocuments?.map((file: any) => (
                      <>
                        <div className="support--doc">
                          {" "}
                          <div className="doc-img">
                            <div className="doc--img">
                              <img
                                src={
                                  FILE_EXTENSION_URL[
                                    file?.docName
                                      ?.split(".")
                                      .pop()
                                      .toLowerCase()
                                  ]
                                }
                                alt=""
                              />
                            </div>
                            <div className="support--doc_name">
                              {file?.docName}{" "}
                            </div>
                            <div className="dot"></div>
                            {formatFileSize(file?.fileSize)}
                          </div>
                          <div className="support-action">
                            <button onClick={() => handelView(file?.fileUrl)}>
                              View
                            </button>
                          </div>
                        </div>
                        <div className="admin-file-comment">
                          <p className="admin-comment"> Comment :</p>{" "}
                          {file?.reason}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </>
          );
        case "REQUESTED":
          return (
            <>
              <div className="history--log">
                <div className="history-row">
                  <div className="history-left">
                    <div className="history-icon">
                      <i className="ri-user-line"></i>
                    </div>
                  </div>

                  <div className="history-right">
                    {" "}
                    <span className="name">
                      {item?.admin_user?.firstName}
                    </span>{" "}
                    has
                    <span className="action"> requested approval </span> for the{" "}
                     {selectedAsset?.issuerDetails?.firstName}{selectedAsset?.issuerDetails?.lastName} {selectedAsset?.name}({selectedAsset?.issuerDetails?.firstName}{selectedAsset?.issuerDetails?.lastName}.{selectedAsset?.symbol
                     } ) SBA7
                    asset.
                    <div className="history-date">
                      {formatDate(item?.createdAt)}
                    </div>
                  </div>
                </div>
                <div className="history-row">
                  <div className="history-data">
                    {item?.filesMetadata?.newDocuments?.map((file: any) => (
                      <div className="support--doc">
                        {" "}
                        <div className="doc-img">
                          <div className="doc--img">
                            <img
                              src={
                                FILE_EXTENSION_URL[
                                  file?.docName?.split(".").pop().toLowerCase()
                                ]
                              }
                              alt=""
                            />
                          </div>
                          <div className="support--doc_name">
                            {file?.docName}{" "}
                          </div>
                          <div className="dot"></div>
                          {formatFileSize(file?.fileSize)}
                        </div>
                        <div className="support-action">
                          <button onClick={() => handelView(file?.fileUrl)}>
                            View
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          );
        default:
          return <></>;
      }
    },
    [
      HistoryData?.data?.history,
      handelView,
      selectedAsset?.name,
      selectedAsset?.symbol,
    ]
  );

  return (
    <>
      <div className="history-header">
        {" "}
        <h2>History</h2>
        <p>Asset minting details for approved/denied request</p>
      </div>
      <div className="history-log">
        {isLoaded ? (
          <div className="">
            {HistoryData?.data?.history.length > 0 ? (
              <div className="">
                {HistoryData?.data?.history.map((item: any, index: number) => (
                  <>{HistoryRow(item, index)}</>
                ))}
              </div>
            ) : (
              <TableEmptyMessage height={"calc(100vh - 400px)"} />
            )}
          </div>
        ) : (
          <div className="history-loader">
            <Loader className="loader-blue" dimension={60} />
          </div>
        )}
      </div>
    </>
  );
};
