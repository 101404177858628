export const IMAGE_GCP_URL =
  "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media";

  export const FILE_EXTENSION_URL: any = {
    pdf: "https://storage.googleapis.com/satschel-assets-public/assets/logo/pdf-logo-c001.svg",
    doc: "https://storage.googleapis.com/satschel-assets-public/assets/logo/doc-logo-c001.svg",
    docx: "https://storage.googleapis.com/satschel-assets-public/assets/logo/docx-logo-c001.svg",
    csv: "https://storage.googleapis.com/satschel-assets-public/assets/logo/csv-logo-c001.svg",
    ppt: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DPPT.png",
    xls: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DXLS.png",
    xlsx: "https://storage.googleapis.com/satschel-assets-public/assets/logo/Property%201%3DXLSX.png",
  };